<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <PilotCard ref="preview" v-bind:title="title" v-bind:example="true">
          <template v-slot:title>
            <h3 class="card-title">
              <slot name="title">Users</slot>
            </h3>
          </template>
          <template v-slot:toolbar>
            <b-dropdown
              size="sm"
              variant="link"
              toggle-class="custom-v-dropdown btn btn-clean btn-light-primary btn-sm btn-icon"
              no-caret
              right
              no-flip
            >
              <template v-slot:button-content>
                <i class="ki ki-bold-more-hor"></i>
              </template>
              <div class="navi navi-hover min-w-md-250px">
                <b-dropdown-text tag="div" class="navi-item">
                  <router-link to="/users/invite" v-slot="{ navigate }">
                    <div
                      class="d-flex align-items-center mb-5 cursor-pointer"
                      @click="navigate"
                    >
                      <div class="symbol symbol-40 symbol-light-info mr-5">
                        <span class="symbol-label">
                          <span class="svg-icon svg-icon-lg svg-icon-info">
                            <inline-svg
                              src="media/svg/icons/Code/Plus.svg"
                            ></inline-svg>
                          </span>
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column font-weight-bold text-left"
                      >
                        <a
                          class="text-dark text-hover-primary mb-0 font-size-lg"
                        >
                          New Users
                        </a>
                        <span class="text-muted">
                          Invite new users
                        </span>
                      </div>
                    </div>
                  </router-link>
                </b-dropdown-text>
                <b-dropdown-text tag="div" class="navi-item">
                  <export-excel
                    class="border-0"
                    :data="users"
                    :fields="exportFields"
                    type="csv"
                    worksheet="users"
                    name="users.csv"
                  >
                    <div class="d-flex align-items-center mb-5 cursor-pointer">
                      <div class="symbol symbol-40 symbol-light-warning mr-5">
                        <span class="symbol-label">
                          <span class="svg-icon svg-icon-lg svg-icon-warning">
                            <inline-svg
                              src="media/svg/icons/Files/Export.svg"
                            ></inline-svg>
                          </span>
                        </span>
                      </div>
                      <div class="d-flex flex-column font-weight-bold">
                        <a
                          class="text-dark text-hover-primary mb-0 font-size-lg"
                        >
                          Export Users
                        </a>
                        <span class="text-muted">
                          Export to excel
                        </span>
                      </div>
                    </div>
                  </export-excel>
                </b-dropdown-text>
                <!-- <b-dropdown-text tag="div" class="navi-item">
                  <div
                    @click="$bvModal.show('searchModal')"
                    class="d-flex align-items-center mb-5 cursor-pointer"
                  >
                    <div class="symbol symbol-40 symbol-light-primary mr-5">
                      <span class="symbol-label">
                        <span class="svg-icon svg-icon-lg svg-icon-primary">
                          <inline-svg
                            src="media/svg/icons/General/Search.svg"
                          ></inline-svg>
                        </span>
                      </span>
                    </div>
                    <div class="d-flex flex-column font-weight-bold">
                      <a class="text-dark text-hover-primary mb-0 font-size-lg">
                        Advanced Search
                      </a>
                      <span class="text-muted">
                        Filter logs
                      </span>
                    </div>
                  </div>
                </b-dropdown-text> -->
                <b-dropdown-text tag="div" class="navi-item">
                  <div
                    @click="refresh()"
                    class="d-flex align-items-center mb-5 cursor-pointer"
                  >
                    <div class="symbol symbol-40 symbol-light-success mr-5">
                      <span class="symbol-label">
                        <span class="svg-icon svg-icon-lg svg-icon-success">
                          <inline-svg
                            src="media/svg/icons/Media/Repeat.svg"
                          ></inline-svg>
                        </span>
                      </span>
                    </div>
                    <div class="d-flex flex-column font-weight-bold">
                      <a class="text-dark text-hover-primary mb-0 font-size-lg">
                        Refresh
                      </a>
                      <span class="text-muted">
                        Refresh user list
                      </span>
                    </div>
                  </div>
                </b-dropdown-text>
              </div>
            </b-dropdown>
          </template>
          <template v-slot:body>
            <template>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <b-form-input
                    v-model="search"
                    id="inline-form-input-name"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="Filter by user name"
                  ></b-form-input>
                </div>
                <div class="col-md-6 col-sm-12">
                  <multiselect
                    v-model.trim="role"
                    :options="roles"
                    track-by="name"
                    label="name"
                    class="select-sm"
                    placeholder="Filter by role"
                    @select="onRoleSelect"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">{{
                      option.name
                    }}</template>
                  </multiselect>
                </div>
              </div>
              <hr />
              <v-data-table
                :headers="headers"
                :items="users"
                :items-per-page="10"
                class="elevation-1"
                :search="search"
              >
                <template v-slot:[`item.email`]="{ item }" class="text-right">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-40 circle symbol-light mr-4 p-0">
                      <span class="symbol-label circle "
                        ><img
                          :src="item.avatar == null ? placeholder : item.avatar"
                          alt=""
                          class="img-fluid align-self-end border shadow circle"
                      /></span>
                    </div>
                    <div>
                      <a
                        class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >{{ item.firstname }} {{ item.lastname }}</a
                      ><span class="text-muted font-weight-bold d-block">{{
                        item.email
                      }}</span>
                    </div>
                  </div>
                </template>
                <template
                  v-slot:[`item.role.name`]="{ item }"
                  class="text-right"
                >
                  <span class="text-dark-75 font-weight-bold d-block">{{
                    item.role.name
                  }}</span>
                </template>
                <template v-slot:[`item.phone`]="{ item }" class="text-right">
                  <span class="text-dark-75 font-weight-bold d-block">{{
                    item.phone
                  }}</span>
                </template>
                <template v-slot:[`item.action`]="{ item }" class="text-right">
                  <div class="w-100 text-right">
                    <span
                      @click="userDetails(item.id)"
                      v-b-tooltip.hover.top="'User Details'"
                      class="btn btn-icon btn-light-primary btn-hover-light-primary btn-sm mr-3"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <inline-svg
                          src="media/svg/icons/Layout/Layout-horizontal.svg"
                        ></inline-svg>
                      </span>
                    </span>
                    <span
                      v-if="
                        hasPermission('Administrator') && item.active == true
                      "
                      @click="updateUserState(item, false)"
                      v-b-tooltip.hover.top="'Deactivate User'"
                      class="btn btn-icon btn-light-danger btn-hover-light-danger btn-sm"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-danger">
                        <inline-svg
                          src="media/svg/icons/Code/Minus.svg"
                        ></inline-svg>
                      </span>
                    </span>
                    <span
                      v-if="
                        hasPermission('Administrator') && item.active == false
                      "
                      @click="updateUserState(item, true)"
                      v-b-tooltip.hover.top="'Activate User'"
                      class="btn btn-icon btn-light-success btn-hover-light-success btn-sm"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-success">
                        <inline-svg
                          src="media/svg/icons/Code/Done-circle.svg"
                        ></inline-svg>
                      </span>
                    </span>
                  </div>
                </template>
              </v-data-table>
            </template>
          </template>
        </PilotCard>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { Multiselect } from "vue-multiselect";
import Swal from "sweetalert2";
import PilotCard from "@/view/content/Card.vue";
import UserService from "@/core/services/user.service";
export default {
  props: {
    title: String
  },
  data() {
    return {
      exportFields: {
        Email: "email",
        Role: "role.name",
        Firstname: "firstname",
        Lastname: "lastname",
        "Phone Number": "phone"
      },
      users: [],
      serverUsers: [],
      search: "",
      headers: [
        {
          text: "User",
          align: "start",
          value: "email"
        },
        { text: "Surname", value: "lastname" },
        { text: "Firstname", value: "firstname" },
        { text: "Role", value: "role.name" },
        { text: "Phone No.", value: "phone" },
        { text: "Actions", align: "end", sortable: false, value: "action" }
      ],
      parent: "",
      role: null,
      roles: []
    };
  },
  components: {
    PilotCard,
    Multiselect
  },
  methods: {
    getColor(status) {
      if (status == "open") return "bg-danger";
      else if (status == "pending") return "bg-warning";
      else return "bg-success";
    },
    onServerSearch() {
      UserService.all(this.search).then(response => {
        this.users = response.data;
        this.serverUsers = response.data;
      });
    },
    onRoleSelect(option) {
      const filterUsers = this.serverUsers.filter(i => i.role_id === option.id);
      this.users = filterUsers;
    },
    refresh() {
      this.getUsers();
      this.search = "";
      this.role = null;
    },
    getUsers() {
      this.roles = this.$store.state.roles.roles;
      UserService.all().then(response => {
        this.users = response.data;
        this.serverUsers = response.data;
      });
    },
    updateUserState(item, activate) {
      const stateAction = activate == true ? "activate" : "deactivate";
      const stateOutcome = activate == true ? "activated" : "deactivated";
      Swal.fire({
        title: "Are you sure?",
        text: `Are you sure you want to ${stateAction} ${item.firstname} ${item.lastname}`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: stateAction,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return UserService.updateState(activate, item.id)
            .then(response => {
              item.active = activate;
              return response.data;
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Operation Failed: ${error.data.message}`
              );
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {
          this.makeToast(
            "success",
            `User ${stateOutcome}`,
            `${item.firstname} ${item.lastname} was successfully ${stateOutcome}`
          );
        }
      });
    },
    userDetails(id) {
      this.$router.push(`/users/details/${id}`);
    },
    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    }
  },
  computed: {
    /**
     * Check if has title
     * @returns {boolean}
     */
    hasTitleSlot() {
      return !!this.$slots["title"];
    },
    ...mapGetters(["currentUser", "layoutConfig", "hasPermission"]),
    placeholder() {
      return this.layoutConfig("avatar");
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Users" }]);
    this.getUsers();
  }
};
</script>
